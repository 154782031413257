require("script-loader!../../node_modules/jquery/dist/jquery.min.js");
require("script-loader!../../node_modules/jquery-ui-dist/jquery-ui.min.js");
require("script-loader!../../node_modules/jquery-mousewheel/jquery.mousewheel.js");
require("script-loader!../../node_modules/bootstrap/dist/js/bootstrap.min.js");
require("script-loader!../../node_modules/moment/min/moment-with-locales.min.js");
require("script-loader!../../node_modules/numeral-legacy/min/numeral.min.js");
require("script-loader!../../node_modules/numeral-legacy/min/languages.min.js");
require("script-loader!../../node_modules/select2/select2.js");
require("script-loader!../../node_modules/angular/angular.min.js");
require("script-loader!../../node_modules/angular-route/angular-route.min.js");
require("script-loader!../../node_modules/angular-resource/angular-resource.min.js");
require("script-loader!../../node_modules/angular-bootstrap/ui-bootstrap.min.js");
require("script-loader!../../node_modules/angular-bootstrap/ui-bootstrap-tpls.min.js");
require("script-loader!../../node_modules/angular-foundation/mm-foundation.min.js");

require("script-loader!../../node_modules/angular-sanitize/angular-sanitize.min.js");
require("script-loader!../../node_modules/angular-ui-sortable/dist/sortable.min.js");
require("script-loader!../../node_modules/angular-ui-utils/modules/format/format.js");
require("script-loader!../../node_modules/angular-dynamic-locale/src/tmhDynamicLocale.js"); // FIXME: Should build this?
require("script-loader!../../node_modules/angular-ui-router/release/angular-ui-router.min.js");
require("script-loader!../../node_modules/underscore/underscore.js");
require("script-loader!../../node_modules/spectrum-colorpicker/spectrum.js");

if (process.env.NODE_ENV === "production") {
  require("script-loader!../../node_modules/react/umd/react.production.min.js");
  require("script-loader!../../node_modules/react-dom/umd/react-dom.production.min.js");
  require("script-loader!../../node_modules/react-dom/umd/react-dom-server.browser.production.min.js");
} else {
  require("script-loader!../../node_modules/react/umd/react.development.js");
  require("script-loader!../../node_modules/react-dom/umd/react-dom.development.js");
  require("script-loader!../../node_modules/react-dom/umd/react-dom-server.browser.development.js");
}

require("script-loader!../../node_modules/ui-select/dist/select.min.js");
require("script-loader!../../node_modules/angular-ui-select/dist/select.min.js");
require("script-loader!../../node_modules/angular-animate/angular-animate.min.js");
require("script-loader!../../node_modules/daterangepicker/daterangepicker.js");

// include moment locales because handsontable.full does not define locales.
require("script-loader!../../node_modules/moment/min/locales.min.js");

var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
window.vizHtmlPrefilter = (notRestoreJQueryLegacyHtmlPrefilter, environment) => {
  jQuery.htmlPrefilter = function(html) {
    if (!notRestoreJQueryLegacyHtmlPrefilter) {
      return html.replace(rxhtmlTag, "<$1></$2>"); //It is to restore the Jquery@3.4.1 htmlPrefilter logic.
    } else {
      if (environment === "development") {
        var regExp = new RegExp("<[^>]+?/>"); //It is for identifying the html self enclosing tags, for debugging purpose.
        if (regExp.test(html)) {
          // console.log("htmlPrefilter>>>>>>>>>>>>>>>>>>>>", html);
        }
      }
      return html;
    }
  };
};
